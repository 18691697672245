import { useContext, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { initializeGlobalVariable } from "../data/globalVariableInitializer";
import { uid } from "../utils/utils";
import { Button } from "./buttons";
import {
  FormContext,
  FormProvider,
  checkContextInputsErrors,
  refreshContextInputs,
} from "./formContext";

const allDialogs = initializeGlobalVariable<Record<any, JSX.Element>>({});

type ConfirmationFunction = (e: any) => any;

interface DialogsInterface {
  id?: string;
  additionalClasses?: (
    | "whiteBody"
    | "small"
    | "medium"
    | "big"
    | "fullPage"
    | "noPadding"
  )[];
  type?: "dialog" | "drawer" | "popover";
  trailing?: JSX.Element;
  leading?: JSX.Element;
  level?: number;
  title: any;
  content?: JSX.Element;
  footer?: JSX.Element | null;
  confirmBeforeClose?: boolean;
  onBeforeClose?: (e: any) => Promise<boolean>;
  onClose?: (e: any) => void;
  onConfirm?: ConfirmationFunction;
  onDelete?: ConfirmationFunction;
  isRaw?: boolean;
  top?: string;
  left?: string;
  skrimColor?: string;
}

class Dialogs {
  promises: Record<string, any> = {};

  async open({
    id,
    additionalClasses,
    type,
    title,
    trailing,
    leading,
    content,
    footer,
    confirmBeforeClose,
    onBeforeClose,
    onClose,
    onConfirm,
    onDelete,
    isRaw,
    level,
    top,
    left,
    skrimColor,
  }: DialogsInterface) {
    id ||= uid();
    return new Promise((resolve, reject) => {
      this.promises[id!] = { resolve, reject };
      if (footer === null) footer = <></>;
      if (!footer && !onConfirm) footer = <></>;

      if (type == "drawer") {
        allDialogs.setIndex(
          id,
          <FormProvider
            value={{
              errors: {},
              onFormSubmit: {},
              onChange: (val) => {},
            }}
          >
            <Drawer
              id={id!}
              level={level || Object.keys(allDialogs).length}
              trailing={trailing}
              leading={leading}
              additionalClasses={additionalClasses}
              title={title}
              footer={footer}
              isOpen={true}
              isRaw={isRaw}
              onConfirm={async (e) => {
                return await onConfirm?.(e);
              }}
              onDelete={onDelete}
              content={content}
              onBeforeClose={
                onBeforeClose ||
                (confirmBeforeClose
                  ? standardOnBeforeClose
                  : async (e) => {
                      return true;
                    })
              }
              onClose={(e) => {
                resolve(true);
                this.close(id);
                onClose?.(e);
              }}
            />
          </FormProvider>
        );
      } else if (type == "popover") {
        //   allDialogs.setIndex(
        //     id,
        //     <MyPopover
        //       skrimColor={skrimColor}
        //       top={top}
        //       left={left}
        //       content={content}
        //       onClose={() => {
        //         resolve(true);
        //         this.close(id);
        //         onClose?.(null);
        //       }}
        //     />
        //   );
      } else {
        allDialogs.setIndex(
          id,
          <Dialog
            id={id!}
            level={Object.keys(allDialogs).length}
            additionalClasses={additionalClasses}
            trailing={trailing}
            leading={leading}
            title={title}
            footer={footer}
            isOpen={true}
            isRaw={isRaw}
            onBeforeClose={
              onBeforeClose ||
              (confirmBeforeClose
                ? standardOnBeforeClose
                : async (e) => {
                    return true;
                  })
            }
            onConfirm={async (e) => {
              return await onConfirm?.(e);
            }}
            onDelete={onDelete}
            content={content}
            onClose={(e) => {
              resolve(true);
              this.close(id);
              onClose?.(e);
            }}
          />
        );
      }
    });
  }

  close(id: string | null | undefined) {
    if (!id)
      id = Object.keys(allDialogs.get())[
        Object.keys(allDialogs.get()).length - 1
      ];
    if (!id) return;
    if (this.promises[id]) this.promises[id].resolve(true);
    allDialogs.setIndex(id, null);
    delete allDialogs.get()[id];
  }

  async closeLastDialog() {
    let ids = Object.keys(allDialogs.get());
    if (!ids.length) return;
    let lastId = ids[ids.length - 1];
    this.close(lastId);
  }

  hasOpenDialog() {
    return Object.keys(allDialogs.get()).length > 0;
  }
}

let dialogsController = new Dialogs();
let openDialog = async (obj: DialogsInterface) => {
  await dialogsController.open({ ...obj, type: "dialog" });
};

let openDrawer = async (obj: DialogsInterface) => {
  await dialogsController.open({ ...obj, type: "drawer" });
};

let openPopover = async (obj: DialogsInterface) => {
  await dialogsController.open({ ...obj, type: "popover" });
};

let closeDialog = (id: any) => {
  dialogsController.close(id);
};
let closeDrawer = (id: any) => {
  dialogsController.close(id);
};

let closeLast = () => {
  dialogsController.closeLastDialog();
};

let hasOpenDialog = () => {
  return dialogsController.hasOpenDialog();
};

function DialogsComponent() {
  let dialogs = allDialogs.useSubscribe(`dialogsComponent`);
  return (
    <>
      {Object.values(dialogs).map((element) => {
        return element;
      })}
    </>
  );
}

function Dialog({
  id,
  level,
  additionalClasses,
  isOpen,
  onClose,
  onBeforeClose,
  onConfirm,
  onDelete,
  title,
  trailing,
  leading,
  footer,
  content,
  isRaw,
}: {
  id?: string;
  level?: number | null;
  additionalClasses?: any[];

  isOpen: boolean;
  onClose?: (e: any) => void;
  onBeforeClose?: (e: any) => Promise<boolean>;
  onConfirm?: ConfirmationFunction;
  onDelete?: ConfirmationFunction;
  title?: any;
  trailing?: any;
  leading?: any;
  footer?: any;
  isRaw?: boolean;
  content?: any;
}) {
  const [isClosing, setIsClosing] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const isMultiPage = Array.isArray(content);

  async function handleClose(e: any, shouldConfirm: boolean = true) {
    if (shouldConfirm && onBeforeClose && (await onBeforeClose(e)) === false)
      return;
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose?.(e);
    }, 150);
  }

  if (!isOpen) return <></>;
  if (isRaw)
    return (
      <div className={`dialog-container `} onClick={handleClose}>
        {content}
      </div>
    );

  return (
    <>
      <div className={`dialog-container `} onClick={handleClose}>
        <div
          id={id}
          className={`dialog  ${additionalClasses?.join(" ")} level-${level} ${
            isClosing ? "closing" : ""
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {title && (
            <div className="dialog-header">
              {leading || (
                <div className="back-button" onClick={handleClose}>
                  <FaChevronLeft size={18} />
                </div>
              )}
              <h2 className="dialog-title">{title}</h2>
              {trailing}
            </div>
          )}
          <div className="dialog-body">
            {isMultiPage ? content[pageNumber] : content}
          </div>
          {footer || (
            <div className="dialog-footer">
              <div
                className="btn secondary main-background"
                onClick={(e) => {
                  handleClose?.(e);
                }}
              >
                Annuler
              </div>
              {onDelete != undefined && (
                <Button
                  color="red"
                  type={"tertiary"}
                  onClick={async (e) => {
                    await onDelete?.(e);
                  }}
                >
                  Supprimer
                </Button>
              )}
              <Button
                onClick={async (e) => {
                  if (isMultiPage && pageNumber < content.length - 1) {
                    setPageNumber(pageNumber + 1);
                    return;
                  }

                  let res = await onConfirm?.(e);
                  if (res === false) return;
                  handleClose?.(e, false);
                }}
              >
                {isMultiPage ? "Suivant" : "Confirmer"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Drawer({
  id,
  additionalClasses,
  level,
  isOpen,
  onClose,
  onBeforeClose,
  onConfirm,
  onDelete,
  title,
  leading,
  trailing,
  footer,
  content,
  isRaw,
}: {
  id: string;
  additionalClasses?: any[];
  level?: number | null;
  isOpen: boolean;
  onClose?: (e: any) => void;
  onBeforeClose?: (e: any) => Promise<boolean>;
  onConfirm?: ConfirmationFunction;
  onDelete?: ConfirmationFunction;
  title?: any;
  leading?: any;
  trailing?: any;
  footer?: any;
  isRaw?: boolean;
  content?: any;
}) {
  const context = useContext(FormContext);
  const [hasError, setHasError] = useState(false);
  let [isClosing, setIsClosing] = useState(false);
  let [pageNumber, setPageNumber] = useState(0);
  additionalClasses ||= ["small"];

  async function handleClose(e: any, shouldConfirm: boolean = true) {
    if (
      shouldConfirm !== false &&
      onBeforeClose &&
      (await onBeforeClose(e)) === false
    )
      return;
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose?.(e);
    }, 150);
  }

  const isMultiPage = Array.isArray(content);
  if (!isOpen) return <div></div>;
  if (isRaw)
    return (
      <div className={`drawer-container `} onClick={handleClose}>
        {content}
      </div>
    );
  return (
    <div className={`drawer-container `} onClick={handleClose}>
      <div
        id={id}
        className={`drawer ${
          additionalClasses?.join(" ") || ""
        } level-${level} ${isClosing ? "closing" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        {title && (
          <div className="drawer-header">
            {leading || (
              <div className="back-button" onClick={handleClose}>
                <FaChevronLeft size={18} />
              </div>
            )}
            <h2 className="drawer-title">{title}</h2>
            {trailing}
          </div>
        )}
        <div className="drawer-body">
          {isMultiPage ? content[pageNumber] : content}
        </div>
        {footer || (
          <div className="drawer-footer">
            {hasError == true ? (
              <div className="double errorMessage mbottom5">
                Certains champs ne sont pas remplis correctement.
              </div>
            ) : (
              <></>
            )}

            <div className="drawer-footer-content">
              <div
                className="btn secondary main-background"
                onClick={async (e) => {
                  await handleClose?.(e);
                }}
              >
                Annuler
              </div>
              {onDelete != undefined ? (
                <Button
                  color="red"
                  type={"tertiary"}
                  onClick={(e) => {
                    onDelete?.(e);
                  }}
                >
                  Supprimer
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                onClick={async (e) => {
                  if (isMultiPage && pageNumber <= content.length - 1) {
                    setPageNumber((prev) => prev + 1);
                    return;
                  }
                  refreshContextInputs(context);
                  let hasInputErrors = checkContextInputsErrors(context);
                  setHasError(hasInputErrors);
                  if (hasInputErrors) return;
                  let res = await onConfirm?.(e);

                  if (res === false) return;
                  await handleClose?.(e, false);
                }}
              >
                <div>{isMultiPage ? "Suivant" : "Confirmer"}</div>
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// function MyPopover({ onClose, top, left, skrimColor, content }) {
//   const [isClosing, setIsClosing] = useState(false);

//   function handleClose() {
//     setIsClosing(true);
//     setTimeout(() => {
//       setIsClosing(false);
//       onClose();
//     }, 150);
//   }

//   return (
//     <div
//       className={`popover-container`}
//       style={{ background: skrimColor }}
//       onClick={handleClose}
//     >
//       <div
//         className={`popover ${isClosing ? "closing" : ""}`}
//         style={{ top: top, left: left }}
//         onClick={(e) => e.stopPropagation()}
//       >
//         {content}
//       </div>
//     </div>
//   );
// }

async function openConfirmDialog({
  id,
  title,
  content,
  onConfirm,
}: {
  id?: string;
  title?: string;
  content?: any;
  onConfirm?: ConfirmationFunction;
}): Promise<boolean> {
  let answer = false;
  await openDialog({
    id,
    title: title || "Confirmation",
    content: content || (
      <div>Voulez-vous vraiment confirmer cette action ? </div>
    ),
    onConfirm: async (e) => {
      answer = true;
      return await onConfirm?.(e);
    },
  });
  return answer;
}

async function openDeleteDialog(
  onConfirm: ConfirmationFunction
): Promise<boolean> {
  return await openConfirmDialog({
    title: "Suppression",
    content: <div>Voulez-vous vraiment supprimer cet élément ?</div>,
    onConfirm: onConfirm,
  });
}

async function standardOnBeforeClose(): Promise<boolean> {
  return await openConfirmDialog({
    title: "Confirmation",
    content: <div>Voulez-vous vraiment fermer ?</div>,
  });
}

export {
  DialogsComponent,
  closeDialog,
  closeDrawer,
  closeLast,
  hasOpenDialog,
  standardOnBeforeClose as onBeforeClose,
  openConfirmDialog,
  openDeleteDialog,
  // ConfirmDialog,
  openDialog,
  openDrawer,
  openPopover
};

