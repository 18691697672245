import { Bounce, toast } from "react-toastify";
export function openToast(
    type: "success" | "error" | "warning" | "info" = "success",
    content: any,
    onClick?: (() => void) | null | undefined,
    duration: number = 5000
  ) {
    toast[type]?.(content, {
      position: "bottom-center",
      autoClose: duration,
      hideProgressBar: false,
      closeOnClick: true,
      onClick: onClick || (() => {}),
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: { zIndex: 999999999 },
  
      transition: Bounce,
    });
  }
  