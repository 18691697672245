import type { BackendFunctionsType } from "@/backend/apiFunctions/functions";

export default async function apiFetch<
  K extends keyof BackendFunctionsType,
  F extends keyof ApiFunction<K>
>(
  categoryId: K,
  functionName: F,
  params: Parameters<ApiFunction<K>[F]>[0],
  errorText?: string | null | undefined
): Promise<ReturnType<ApiFunction<K>[F]>> {
  let result = await fetch(
    `${process.env.BACKEND_BASE_URL}/nextApi/${categoryId}/${
      functionName as string
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(params || {}),
    }
  );

  if (!result.ok) throw new Error(`${result.statusText}`);
  let json = await result.json();
  if (json.error) throw new Error(`${json.error}`);
  return json.result;
}

type ApiFunction<K extends keyof BackendFunctionsType> = {
  [F in keyof BackendFunctionsType[K]]: BackendFunctionsType[K][F] extends (
    params: infer P
  ) => Promise<infer R>
    ? (params: P) => R
    : never;
};
