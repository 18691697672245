import {
  Patient,
  PatientItemStatus,
  PatientLight,
  Permission,
  Reference,
  ReferenceCategory,
  ServerData,
  Service,
  TableName,
  User,
  referenceCategories,
} from "@/src/data/allTypes";
import "moment/locale/fr";
moment.locale("fr");

import { Bounce, toast } from "react-toastify";
import moment from "moment";
import "moment/locale/fr";
import { USER } from "../data/globalVariables";
import apiFetch from "./apiFetch";
moment.locale("fr");

export function getAge(patient: Patient | PatientLight | null | undefined) {
  if (!patient) return null;
  let years = moment().diff(moment(patient?.birth), "years");
  let months = moment().diff(moment(patient?.birth), "months");
  if (years < 1) return `${months} mois`;
  if (months < 1)
    return `${moment().diff(moment(patient?.birth), "days")} jours`;
  return `${years} ans`;
}

export function getName(patient: Patient | null | undefined) {
  if (!patient) return null;
  return `${patient?.lastName || ""} ${patient?.firstName || ""}`;
}

export function getUserShortName(user: User | null | undefined) {
  if (!user) return null;
  if (typeof user != "object") return null;
  let firstName = user.firstName || "";
  return `${getGrade(user)} ${firstName[0] || ""}. ${user.lastName || ""}`;
}

export function getUserFullName(user: User | null | undefined) {
  if (!user) return null;
  if (typeof user != "object") return null;
  return `${getGrade(user)} ${user.lastName || ""} ${user.firstName || ""}`;
}

export function getGrade(user: User | null | undefined) {
  if (!user) return "";
  if (isNurse(user) || isAdmin(user)) return "";
  let grade = user.grade;
  if (grade == "Autre") return "";
  if (grade == "Pr") return "Pr";
  if (grade == "Dr") return "Dr";
  if (grade == "Résident") return "Dr";
  if (grade == "Interne") return "Dr";
  if (grade == "Assitant") return "Dr";
  if (grade == "MCA") return "Dr";
  if (grade == "MCB") return "Dr";
  if (grade == "Externe") return "";
  if (user.role?.name?.toLowerCase().includes("decin")) return "Dr";
  return user.grade || "Dr";
}

export function getExamCategoryName(
  categoryId: ReferenceCategory | null | undefined
) {
  if (!categoryId) return;
  let category = referenceCategories[categoryId];

  return category?.name || "";
}

export function getTotal(
  counts: Record<string, { date: string; count: number }> | undefined | null
) {
  if (!counts) return 0;
  let total = 0;
  for (const key in counts) {
    total += counts[key].count;
  }
  return total;
}

export function isCardiologist(user: User | null | undefined) {
  return user?.role?.name?.toLowerCase()?.includes("cardiologue");
}

export function isAdmin(user: User | null | undefined) {
  return (
    user?.id == "users:1709198699135q0fnhb8dr" ||
    user?.role?.name?.toLowerCase()?.includes("admin") ||
    user?.role?.name?.toLowerCase()?.includes("chef")
  );
}

export function isSuperAdmin(user: User | null | undefined) {
  return user?.role?.name?.toLowerCase()?.includes("super");
}

export function isNurse(user: User | null | undefined) {
  return ["infirmier", "nurse"].includes(
    user?.role?.name?.toLowerCase() || "none"
  );
}

export function isReception(user: User | null | undefined) {
  let roleName = user?.role?.name?.toLowerCase() || "none";
  for (let str of ["reception", "accueil", "secr"]) {
    if (roleName.includes(str)) return true;
  }
  return false;
}

export function isBureauAdmission(user: User | null | undefined) {
  return USER.get()?.role?.name?.toLowerCase()?.includes("admission");
}

export function getOptionsFromRecord(
  object: Record<string, { id: string; name: string }> | null | undefined
) {
  if (!object) return [];
  return Object.values(object).map((value) => {
    return { value: value.id, label: value.name };
  });
}

export function cleanHtmlEscaping(content: string) {
  return content
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("<br>", "")
    .replaceAll("&amp;", "&")
    .replaceAll("&quot;", '"')
    .replaceAll("&apos;", "'")
    .replaceAll("&nbsp;", " ")
    .replaceAll("&middot;", "·")
    .replaceAll("&bull;", "•")
    .replaceAll("&hellip;", "…")
    .replaceAll("&ndash;", "–")
    .replaceAll("&mdash;", "—")
    .replaceAll("&lsquo;", "'")
    .replaceAll("&rsquo;", "’")
    .replaceAll("&rdquo;", "”")
    .replaceAll("&laquo;", "«")
    .replaceAll("&raquo;", "»");
}

export function generateNewId(
  oldId: string | number | null | undefined,
  table: TableName
): any {
  if (oldId === "" || oldId === null || oldId === undefined)
    return oldId?.toString();
  if (oldId?.toString().includes(`${table}:`)) return oldId;

  return `${table}:${
    btoa(unescape(encodeURIComponent(oldId?.toString() || ""))) || ""
  }`
    .replaceAll("=", "_")
    .replaceAll("+", "_")
    .replaceAll("/", "_");
}

export function getOptionValueStr(value: any): string | null | undefined {
  if (Array.isArray(value)) return value.map(getOptionValueStr).join(",");
  if (typeof value == "object")
    return value.value?.toString() || value?.name?.toString() || "";
  return value?.toString();
}



export function checkReferenceGuidelines(reference: Reference): boolean {
  if (!reference) return false;
  let name = (reference.name || "")?.toLowerCase();
  if (name.includes("+")) return false;
  if (name.includes(" et ")) return false;
  if (name.includes(" depuis ")) return false;
  if (name.includes(" . ")) return false;
  if (name.includes(" , ")) return false;
  return true;
}


export async function ajax(functionName: string, data: Record<any, any> | any) {
  // await simulateSlowNetwork(functionName, data, 40);

  let result = await fetch(`./api/${functionName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
  });

  let json = await result.json();
  if (json.error) throw new Error(json.error);
  // await simulateSlowNetwork(functionName, json, 100);
  return json;
}

async function simulateSlowNetwork(name: string, json: any, kbs: number) {
  if (process.env.NODE_ENV !== "development") return;
  let length = JSON.stringify(json)?.length;
  let kb = parseInt((length / 1024).toString());
  console.log(`${name?.replace("general/", "")} ${kb}KB`, json);
  await sleep((kb / kbs) * 1000);
}

export function getRemedType(): "REMED" | "FORENXPERT" | "ECG" | "ECRF" {
  return process.env.REMED_TYPE as any;
}

export function flutterPost(message: {
  type:
    | "help"
    | "request_token"
    | "request_notification_permission"
    | "close_app";
}) {
  (window as any)?.Flutter?.postMessage?.(JSON.stringify(message));
}

export function uid(): string {
  return getRandomString(14);
}

export function getRandomString(length: number) {
  return (Math.random() + 1).toString(36).substring(3, length + 3);
}

export function getRandomInt(length: number) {
  return parseInt((Math.random() + 1).toString().substring(3, length + 3));
}

export function jsonAbstract(value: any) {
  if (!value) return value;
  return JSON.parse(JSON.stringify(value));
}

let DEBOUNCES: Record<string, NodeJS.Timeout> = {};
export function debounce(name: string, callback: () => void, duration: number) {
  try {
    clearTimeout(DEBOUNCES[name]);
  } catch (e) {}
  DEBOUNCES[name] = setTimeout(callback, duration);
}

export function stopDebounce(name: string) {
  try {
    clearTimeout(DEBOUNCES[name]);
  } catch (e) {}
}

export function fromNow(date: string | null | undefined): string | null {
  if (!date) return null;
  //more than a day
  if (moment().diff(moment(date), "days") > 1)
    return moment(date).format("LL à HH:mm");
  return moment(date).fromNow();
}

export function getDayCount(
  startDate: string | null | undefined,
  date: string | null | undefined
): string | null {
  if (!date || !startDate) return null;
  return `J${moment(date).diff(moment(startDate), "days")}`;
}

export function toBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export async function sleep(delayInms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export function openToast(
  type: "success" | "error" | "warning" | "info" = "success",
  content: any,
  onClick?: (() => void) | null | undefined,
  duration: number = 5000
) {
  toast[type]?.(content, {
    position: "bottom-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    onClick: onClick || (() => {}),
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: { zIndex: 999999999 },

    transition: Bounce,
  });
}

export function rgbaToHex(color: string | null | undefined) {
  if (!color) return color;
  color = color.trim().toLowerCase();
  if (color.startsWith("rgba")) {
    let parts = color
      .replace("rgba(", "")
      .replace(")", "")
      .split(",")
      .map((part) => parseInt(part));
    return `#${parts[0].toString(16)}${parts[1].toString(
      16
    )}${parts[2].toString(16)}`;
  }
  return color;
}

export function colorToRgba(
  color: string | null | undefined,
  opacity: number = 1
) {
  if (!color) return color;
  color = color.trim().toLowerCase();
  if (color.startsWith("#")) {
    color = color.substring(1);
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  }

  if (color.startsWith("hsl")) {
    return color.replace("hsl", `hsla`).replace(")", `,${opacity})`);
  }
  if (color.startsWith("rgb")) {
    return color.replace("rgb", `rgba`).replace(")", `,${opacity})`);
  }
  if (color.startsWith("rgba")) {
    return color.replace(/,([^,]+)\)/, `,${opacity})`);
  }
  return color;
}

export function updateOrPush<T>(
  array: T[] | null | undefined,
  item: T,
  filterFunction?: (item: T) => boolean
): T[] {
  if (!array) array = [];

  let index = array.findIndex(
    filterFunction || ((i: any) => i?.["id"] === item?.["id" as keyof T])
  );

  if (index != -1) {
    array[index] = item;
  } else {
    array.push(item);
  }
  return array;
}

export function formatDate(
  date: string | null | undefined,
  format: "LL" | "LL HH:mm" | "LL à HH:mm" | "HH:mm"
) {
  if (!date) return "";
  let result = moment(date).format(format);
  if (result == "Invalid date") return "";
  return result;
}

export function getFileType(
  name: string | null | undefined
): "image" | "file" | "dicom" | "video" | null {
  if (!name) return null;
  let parts = name.split(".");
  let extension = parts[parts.length - 1]?.toLowerCase();
  if (extension == "db") return "file";
  if (extension == "dcm" || extension == "dicom") return "dicom";
  if (["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(extension))
    return "image";
  if (["mp4", "avi", "mov", "mkv", "webm"].includes(extension)) return "video";
  return "file";
}

export function getImgUrl(
  upload: { name: string | null | undefined } | null | undefined
): string {
  const name = upload?.name;
  if (!name) return "";
  return `${process.env.BACKEND_BASE_URL}/my_uploads/${name}`;
}

export function getItemId(item: any) {
  if (!item) return null;
  if (typeof item == "string" || typeof item == "number") return item;
  return item.id;
}
export function removeIds(items: any): any {
  if (!items) return items;
  if (typeof items == "string" || typeof items == "number") return items;
  if (Array.isArray(items)) return items.map(removeIds);
  return { ...items, id: null };
}

export function getItemsIds(items: any[]) {
  if (!items) return [];
  if (!Array.isArray(items)) return [getItemId(items)];
  return items.map(getItemId);
}
export function itemsIncludeId(items: any, id: any): boolean {
  if (!items) return false;
  for (let item of items) {
    if (getItemId(item) != null && getItemId(item) == getItemId(id))
      return true;
  }
  return false;
}

export function isMobile() {
  if (typeof window == "undefined") return false;
  return window.innerWidth < 800;
}

export function decodeBase64(str: any) {
  if (!str) return str;
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

export function decodeAndParseBase64(
  str: any
): Record<any, any> | any[] | null {
  if (!str) return str;
  try {
    return JSON.parse(decodeBase64(str));
  } catch (e) {
    return null;
  }
}

export function encodeBase64(str: any) {
  if (!str) return str;
  if (typeof str == "object") str = JSON.stringify(str);
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt("0x" + p1, 16));
    })
  );
}

export function getUserId(): any {
  return USER.get()?.id;
}

export function getServiceId(): any {
  return USER.get()?.service?.id;
}

export function hasPermission(
  selectedPermission: Permission,
  user?: User | null | undefined
): boolean {
  user ||= USER.get();
  return (
    user?.role?.permissions?.some(
      (permission) => selectedPermission == permission
    ) || false
  );
}

export function getLocalStorageItem(key: string) {
  if (typeof window != "undefined") {
    return localStorage.getItem(key);
  }
  return null;
}

