"use client";
import {
  closeLast,
  DialogsComponent,
  hasOpenDialog,
  openConfirmDialog,
} from "@/src/components/dialogs";
import { CONFIG, USER } from "@/src/data/globalVariables";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import moment from "moment";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Loading from "../components/loading";
import { ServerData } from "../data/allTypes";
import apiFetch from "../utils/apiFetch";
import { flutterPost } from "../utils/utils";

const queryClient = new QueryClient();

export default function PageRoot({ children }: { children: any }) {
  const [serverData, setServerData] = useState<ServerData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const pathName = usePathname();

  useEffect(() => {
    initializeWindowFunctions();
    parseServerData();
  }, []);

  async function parseServerData() {
    let data = await localSessionData();
    console.log("the sessions data", data);

    if (!data?.loggedInUser && pathName != "/login")
      return (window.location.href = "/login");
    CONFIG.set(data?.referentielConfig);
    USER.set(data?.loggedInUser);
    setServerData(data);
  }

  async function localSessionData() {
    let data = sessionStorage.getItem("serverData");
    if (data) return JSON.parse(data) as ServerData;
    setIsLoading(true);
    let serverData = await apiFetch("general", "getServerData", {
      req: null as any,
      res: null as any,
    });
    sessionStorage.setItem("serverData", JSON.stringify(serverData));

    setIsLoading(false);
    return serverData;
  }

  return (
    <QueryClientProvider client={queryClient} key={USER.get()?.id}>
      <ToastContainer />
      <DialogsComponent></DialogsComponent>
      {serverData == null ? (
        <div >
          <div className="background-shape shape-1"></div>
          <div className="background-shape shape-2"></div>
          <div className="background-shape shape-3"></div>
          <div className="background-shape shape-4"></div>
          {isLoading == true && <div className="c1 center " style={{ height: "100vh" }}><Loading color="blue"></Loading></div>}
        </div>
      ) : (
        <div className="background">
          <div className="background-shape shape-1"></div>
          <div className="background-shape shape-2"></div>
          <div className="background-shape shape-3"></div>
          <div className="background-shape shape-4"></div>
          <div className="background-shape shape-5"></div>
          {children}
        </div>
      )}
    </QueryClientProvider>
  );
}

function initializeWindowFunctions() {
  (window as any).back = () => {
    console.log("Going back!");
    if (hasOpenDialog()) {
      closeLast();
    } else {
      openConfirmDialog({
        title: "Quitter l'application",
        content: "Voulez-vous vraiment quitter l'application?",
        onConfirm: () => {
          flutterPost({ type: "close_app" });
        },
      });
    }
  };

  (window as any).help = () => {
    flutterPost({ type: "help" });
  };

  (window as any).onTokenReceived = async function (
    token: string | null | undefined
  ) {
    if (!token) return;
    let user = USER?.get();
    if (!user) return;
    if (user.token == token) return;
    user.token = token;
    user.lastTokenDate = moment().format("YYYY-MM-DD HH:mm:ss");
    try {
      await apiFetch(
        "users",
        "addOne",
        { user },
        "Erreur lors de la mise à jour du token"
      );
    } catch (e) {
      console.log(e);
    }
  };
}
