"use client";
import { Language, RemedConfig, User } from "@/src/data/allTypes";
import { initializeGlobalVariable } from "./globalVariableInitializer";

export const CARDIOLOGY_SPECIALIITY_ID = "specialities:Mw__";
export const GENERAL_SPECIALITY_ID = "specialities:MTg_";

export const USER = initializeGlobalVariable<User | null | undefined>(null);
export const CONFIG = initializeGlobalVariable<RemedConfig | null | undefined>(
  null
);
export const LANGUAGE = initializeGlobalVariable<{
  current: Language | null | undefined;
}>({ current: "fr" });
export const VERSION = initializeGlobalVariable<string | null | undefined>(
  null
);

export const GALLERY_IMAGES = initializeGlobalVariable<
  { src: string | null | undefined }[] | null | undefined
>(null);
