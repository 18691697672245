import { useEffect, useState } from "react";
let allListeners: Record<any, any> = {};
let localEventListeners: Record<string, Record<string, Function>> = {};

export function initializeGlobalVariable<T>(initialValue: T) {
  let name: string = Math.random().toString(36).substring(7);
  let value: T = initialValue;

  function useSubscribe(id: string) {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      const handleVarChange = () => {
        setLocalValue(value);
      };

      allListeners[name] ||= {};
      localEventListeners[name] ||= {};
      if (allListeners[name][id]) return;
      allListeners[name][id] = handleVarChange;
      localEventListeners[name][id] = handleVarChange;

      return () => {
        delete allListeners[name][id];
        delete localEventListeners[name][id];
      };
    }, []);

    return localValue;
  }

  function set(newValue: T) {
    value = newValue;
    notifyListeners();
    return value;
  }

  function get(): T {
    return value;
  }

  function setIndex(index: any, newValue: any) {
    if (!value) return;
    if (typeof value != "object") return;
    value = { ...value, [index]: newValue };
    notifyListeners();
  }

  function notifyListeners() {
    for (let listenerFunction of Object.values(
      localEventListeners[name] || {}
    )) {
      listenerFunction?.();
    }
  }

  return { get, useSubscribe, set, setIndex, notifyListeners };
}
