export default function Loading({
  size = 50,
  girth = 5,
  color = 'blue',
  railColor = 'lightgray',
}: {
  size?: number;
  girth?: number;
  color?: string;
  railColor?: string;
}) {
  return (
    <div
      className="loader"
      style={{
        height: size,
        width: size,
        borderWidth: girth,
        borderTopColor: color,
        borderLeftColor: railColor,
        borderBottomColor: railColor,
        borderRightColor: railColor,
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
      }}
    ></div>
  );
}