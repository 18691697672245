import Loading from "@/src/components/loading";
import { openToast } from "@/src/utils/toasts";
import { MouseEventHandler, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiEdit2, FiMoreVertical } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";

export type ButtonColor =
  | "simple"
  | "gray"
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "purple"
  | "white"
  | "pink";

export const buttonColors = {
  simple: {
    id: "simple",
    name: "Simple",
    icon: null,
    color: "#1c4d9b	",
    secondaryColor: "	#2596be",
  },
  white: {
    id: "white",
    name: "Blanc",
    icon: null,
    color: "rgba(255, 255, 255,1)",
    secondaryColor: "rgba(255, 255, 255,0.1)",
  },
  gray: {
    id: "gray",
    name: "Gris",
    icon: null,
    color: "rgba(0, 0, 0,0.8)",
    secondaryColor: "rgba(0, 0, 0,0.1)",
  },
  red: {
    id: "red",
    name: "Rouge",
    icon: null,
    color: "#e93041",
    secondaryColor: "rgba(255, 0, 0,0.1)",
  },
  green: {
    id: "green",
    name: "Vert",
    icon: null,
    color: "rgba(2, 194, 75, 1)",
    secondaryColor: "rgba(2, 194, 75,0.12)",
  },
  blue: {
    id: "blue",
    name: "Bleu",
    icon: null,
    color: "	#1c4d9b",
    secondaryColor: "	#2595BE6E",
  },
  orange: {
    id: "orange",
    name: "Orange",
    icon: null,
    color: "rgba(255, 124, 2,1)",
    secondaryColor: "rgba(255, 124, 2,0.1)",
  },
  purple: {
    id: "purple",
    name: "Violet",
    icon: null,
    color: "rgba(144, 0, 255,1)",
    secondaryColor: "rgba(144, 0, 255,0.1)",
  },
  pink: {
    id: "pink",
    name: "Rose",
    icon: null,
    color: "rgba(255, 77, 115,1)",
    secondaryColor: "rgba(255, 77, 115,0.1)",
  },
};

export function Button({
  type = "primary",
  isDisabled,
  additionalStyle,
  icon,
  color = "blue",
  onClick,
  children,
}: {
  type?: "primary" | "secondary" | "tertiary" | "quaternary";
  color?: ButtonColor;
  icon?: any;
  isDisabled?: boolean;
  additionalStyle?: React.CSSProperties;
  onClick: MouseEventHandler;
  children?: any;
}) {
  let colorInfo = color ? buttonColors[color] : null;

  let [isLoading, setIsLoading] = useState(false);

  let child = children;
  if (icon)
    child = (
      <div className="cauto-1 gap5 acenter " style={{ width: "100%" }}>
        {icon} <span className="mright10">{children}</span>
      </div>
    );

  if (isLoading) {
    child = <Loading size={15} girth={2}></Loading>;
  }

  return (
    <div
      className={`btn ${isLoading ? "loading" : ""} ${isDisabled ? "disabled" : ""} ${type} ${color}-btn`}
      style={{
        background:
          type == "tertiary"
            ? colorInfo?.secondaryColor
            : type == "secondary"
            ? undefined
            : colorInfo?.color,
        borderColor: type == "secondary" ? colorInfo?.color : undefined,
        color:
          type == "secondary" || type == "tertiary"
            ? colorInfo?.color
            : undefined,
        ...(additionalStyle || {}),
      }}
      onClick={async (e) => {
        try {
          e.stopPropagation();
          if (isDisabled) return;
          if (isLoading == true) return;
          setIsLoading(true);
          if (onClick) await onClick(e);
          setIsLoading(false);
        } catch (err) {
          console.log("the errooor", err);
          setIsLoading(false);
          openToast("error", (err as any)?.message || err);
          throw e;
        }
      }}
    >
      {child}
    </div>
  );
}

export function IconButton({
  isMain,
  height,
  width,
  isSecondary,
  onClick,
  children,
}: {
  isMain?: boolean;
  height?: number;
  width?: number;
  isSecondary?: boolean;
  onClick: (e: any) => void;
  children?: any;
}) {
  return (
    <div
      className={`iconButton ${isSecondary ? "secondary" : ""} ${
        isMain ? "main" : ""
      }`}
      style={{ height, width }}
      onClick={async (e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
}

export function CornerIconButton({
  type,
  position = "primary",
  iconSize,
  onClick,
  children,
}: {
  type: "edit" | "delete" | "remove" | "more" | "add";
  iconSize?: number;
  position?: "primary" | "secondary";
  onClick: (e: any) => void;
  children?: any;
}) {
  const [isLoading, setIsLoading] = useState(false);

  function getIcon() {
    if (children) return children;
    if (type === "edit") return <FiEdit2 size={iconSize || 16} />;
    if (type === "more") return <FiMoreVertical size={iconSize || 16} />;
    if (type === "add") return <IoMdAdd size={iconSize || 16} />;
    if (type === "delete") return <FaRegTrashAlt size={iconSize || 16} />;
    if (type === "remove")
      return <IoClose size={iconSize || 15} color="white"></IoClose>;
    return <FiEdit2 size={iconSize || 18} />;
  }

  async function onButtonClick(e: any) {
    try {
      e.stopPropagation();
      if (isLoading) return;
      setIsLoading(true);
      await onClick(e);
      setIsLoading(false);
    } catch (e: any) {
      openToast("error", e?.message || e?.toString());
      setIsLoading(false);
      throw e;
    }
  }

  return (
    <div
      className={`cornerButton ${position} ${isLoading ? "loading" : ""}`}
      onClick={onButtonClick}
    >
      {isLoading ? <Loading size={20} girth={3}></Loading> : getIcon()}
    </div>
  );
}



