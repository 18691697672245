import React, { useContext, useEffect, useState } from "react";

export type FormContextData = {
  errors: Record<string, string | null | undefined>;
  onFormSubmit: Record<string, (val: any) => void>;
  onChange: (val: any) => void;
};

export const FormContext = React.createContext<FormContextData | undefined>(
  undefined
);

export function FormProvider({
  value,
  children,
}: {
  value: FormContextData;
  children: any;
}) {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

export function InputChecker({
  id,
  name,
  isRequired,
  value,
  isUploads,
  hasChanged,
  children,
}: {
  id: string | null | undefined;
  name: string | null | undefined;
  isRequired?: boolean;
  value: any;
  isUploads?: boolean;
  hasChanged: boolean;
  children: any;
}) {
  const context = useContext(FormContext);

  const [localHasChanged, setLocalHasChanged] = useState(hasChanged);
  if (context)
    context.onFormSubmit[id || name || ""] = () => {
      hasChanged = true;
      setLocalHasChanged(true);
    };

  useEffect(() => {
    addErrors();
  }, [value]);
  function addErrors() {
    if (!context) return;
    context.errors[id || name || ""] = getErrorText();
 
  }

  function getErrorText() {
    if (isRequired && !value) return `Champs obligatoire`;
    if (isUploads) {
      if (Array.isArray(value)) return "";
      if (typeof value != "object") return "";
      for (let upload of Object.values((value || {}) as Record<any, any>)) {
        if (!upload.name) continue;
        if (upload.progress == 0) continue;
        if (upload.progress == 100) continue;
        return `Certaines pièces jointes ne sont pas encore entièrement chargées.`;
      }
    }
    return "";
  }

  let error = getErrorText();
  let shouldShowError =
    error.length > 0 && (hasChanged || localHasChanged == true);

  return (
    <div className={` ${shouldShowError ? "errorField" : ""}`}>
      {children}
      {shouldShowError ? (
        <div style={{ color: "red" }}>{error}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export function refreshContextInputs(
  context: FormContextData | null | undefined
) {
  if (!context) return;
  for (let func of Object.values(context.onFormSubmit)) {
    func?.("");
  }
}

export function checkContextInputsErrors(
  context: FormContextData | null | undefined
): boolean {
  if (!context) return false;
  for (let err of Object.values(context.errors)) {
    console.log("finding error", err);
    if (err) return true;
  }
  return false;
}
